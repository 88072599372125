import { Exam, Question, Result } from './type'; // Adjust this import to your file structure
import sanitizeHtml from 'sanitize-html';
import DOMPurify from 'dompurify';
import { MathJax } from 'better-react-mathjax';
import { Box } from '@mui/material';
import { useEffect } from 'react';

declare global {
  interface Window {
    MathJax: any;
  }
}

/**
 * Extract multiple-choice questions (MCQs) from HTML content.
 * @param html - The HTML string content extracted from DOCX.
 * @returns An array of Question objects.
 */

//Example of text from DOCX file
/*
ĐỀ SỐ 1
PHẦN I. Câu trắc nghiệm nhiều phương án lựa chọn. Thí sinh trả lời từ câu 1 đến câu 18. Mỗi câu hỏi thí sinh chỉ chọn một phương án.
Câu 1: Nội dung câu 1.
A. Câu a.	B. Câu b.	C. Câu c.	D. Câu d.
Hướng dẫn
Chọn A
PHẦN II. Câu trắc nghiệm đúng sai. Thí sinh trả lời từ câu 1 đến câu 4. Trong mỗi ý a), b), c), d) ở mỗi câu, thí sinh chọn đúng hoặc sai.
Câu 1: Nội dung câu 1.
a) Câu a. 
b) Câu b. 
c) Câu c.
d) Câu d.
Hướng dẫn
Giải thích câu a ⇒ a) Sai
Giải thích câu b ⇒ b) Đúng
Giải thích câu c ⇒ c) Đúng
Giải thích câu d ⇒ d) Sai
PHẦN III. Câu trắc nghiệm trả lời ngắn. Thí sinh trả lời từ câu 1 đến câu 6
Câu 1: Nội dung câu 1.
Hướng dẫn
Giải thích câu 1
Trả lời ngắn: 0,01
*/

//Example of html from DOCX file
/*
<p><span style="font-weight: bold; ">ĐỀ SỐ 1</span></p>
<p><span style="font-weight: bold; ">PHẦN I. Câu trắc nghiệm nhiều phương án lựa chọn.</span><span style=""> Thí sinh trả lời từ câu 1 đến câu 18. Mỗi câu hỏi thí sinh chỉ chọn một phương án.</span></p>
<p><span style="font-weight: bold; ">Câu 1:</span><span style=""> Nội dung câu 1.</span></p>
<p><span style="font-weight: bold; ">A.</span><span style=""> Câu a.</span><span style="font-weight: bold; ">B.</span><span style="font-style: italic; "> </span><span style="">Câu b.</span><span style="font-weight: bold; ">C.</span><span style=""> Câu c.</span><span style="font-weight: bold; ">D.</span><span style=""> Câu d.</span></p>
<p><span style="font-weight: bold; ">Hướng dẫn</span></p>
<p><span style="font-weight: bold; ">Chọn A</span></p>
<p><span style="font-weight: bold; ">PHẦN II. Câu trắc nghiệm đúng sai. </span><span style="">Thí sinh trả lời từ câu 1 đến câu 4. Trong mỗi ý </span><span style="font-weight: bold; ">a), b), c), d)</span><span style=""> ở mỗi câu, thí sinh chọn đúng hoặc sai.</span></p>
<p><span style="font-weight: bold; ">Câu 1: </span><span style="">Nội dung câu 1.</span></p>
<p><span style="font-weight: bold; ">a)</span><span style=""> Câu a. </span></p>
<p><span style="font-weight: bold; ">b)</span><span style=""> Câu b. </span></p>
<p><span style="font-weight: bold; ">c)</span><span style=""> Câu c.</span></p>
<p><span style="font-weight: bold; ">d)</span><span style=""> Câu d.</span></p>
<p><span style="font-weight: bold; ">Hướng dẫn</span></p>
<p><span style="">Giải thích câu a ⇒</span><span style="font-weight: bold; "> a) Sai</span></p>
<p><span style="">Giải thích câu b ⇒</span><span style="font-weight: bold; "> b) Đúng</span></p>
<p><span style="">Giải thích câu c ⇒</span><span style="font-weight: bold; "> c) Đúng</span></p>
<p><span style="">Giải thích câu d ⇒</span><span style="font-weight: bold; "> d) Sai</span></p>
<p><span style="font-weight: bold; ">PHẦN III. Câu trắc nghiệm trả lời ngắn. </span><span style="">Thí sinh trả lời từ câu 1 đến câu 6</span></p>
<p><span style="font-weight: bold; ">Câu 1:</span><span style=""> Nội dung câu 1.</span></p>
<p><span style="font-weight: bold; ">Hướng dẫn</span></p>
<p><span style="">Giải thích câu 1</span></p>
<p><span style="font-weight: bold; ">Trả lời ngắn:</span><span style=""> 0,01</span></p>
*/

// Regex patterns to match sections, questions, options, and answers
const sectionNumberPattern = /PHẦN\s(\d+)/g; // PHẦN 1, PHẦN 2, PHẦN 3, ...
const sectionIPattern = /PHẦN\s([IVXLCDM]+)/g; // PHẦN I, PHẦN II, PHẦN III, ...
const questionPattern =
  /Câu\s(\d+)(?:\[[^\]]*\])?[:.]\s*([\s\S]*?)(?=(?:Câu\s\d+(?:\[[^\]]*\])?[:.]|$))/g; // /Câu\s(\d+):\s*([\s\S]*?)(?=(?:Câu\s\d+:|$))/g;
const optionMCQPattern = /([ABCD])\.\s*([\s\S]*?)(?=(?:[ABCD]\.|$))/g; // A. ... B. ... C. ... D. ...
const optionMCTFPattern = /([abcd])\)\s*([\s\S]*?)(?=(?:[abcd]\)|$))/g; // a) ... b) ... c) ... d) ...
const answerMCQPattern = /Chọn\s*([ABCD])/; // Chọn A, Chọn B, Chọn C, Chọn D
const answerMCTFPattern = /([abcd])\)\s*(Đúng|Sai)/; // a) Đúng, b) Sai, c) Đúng, d) Sai
const answerSAPattern = /Trả lời ngắn:\s*([\s\S]*)/; // Trả lời ngắn: ...
const explainPattern = /Hướng dẫn\s*([\s\S]*?)(?=(?:Câu\s\d+:|$))/g; // Hướng dẫn ... (Answer content)
const explainPattern2 = /Lời giải\s*([\s\S]*?)(?=(?:Câu\s\d+:|$))/g; // Lời giải ... (Answer content)
//Sử dụng các thông tin sau cho Câu [startQuestion] và|đến Câu [endQuestion]: ... (content)

const reuseContentPattern =
  /Sử dụng các thông tin sau cho Câu\s(\d+) và Câu\s(\d+):\s*([\s\S]*?)(?=(?:Câu\s\d+:|$))/g;

const reuseContentPattern2 =
  /Sử dụng các thông tin sau cho Câu\s(\d+) đến Câu\s(\d+):\s*([\s\S]*?)(?=(?:Câu\s\d+:|$))/g;

// ĐỀ VẬT LÝ THAM KHẢO CỦA BỘ 2025
// Đề số 1
// Pattern to match the exam name is ĐỀ ... hoặc Đề ...
const examNamePattern = /<p><span[^>]*>\s*(Đ[ềỀ][^<]*)<\/span><\/p>/i;

export const extractExamName = (html: string): string => {
  const match = html.match(examNamePattern);
  return match ? stripHTMLTags(match[1]) : '';
};

export const extractQuestions = async (html: string): Promise<Question[]> => {
  const questions: Question[] = [];

  let currentSection = '';

  // Combine both section patterns (numbers and Roman numerals) to find sections
  let sectionMatches = [
    ...html.matchAll(sectionNumberPattern),
    ...html.matchAll(sectionIPattern),
  ];

  // Sort sections based on their appearance in the HTML content
  sectionMatches.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));

  // If no sections are found, treat the entire HTML as one large section
  if (sectionMatches.length === 0) {
    currentSection = '';
    await extractQuestionsFromContent(html, questions, currentSection);
  } else {
    // Loop through each section found in the HTML content
    for (
      let sectionIndex = 0;
      sectionIndex < sectionMatches.length;
      sectionIndex++
    ) {
      const sectionMatch = sectionMatches[sectionIndex];
      currentSection = sectionMatch[0]; // e.g., "PHẦN I" or "PHẦN 1"

      // Find all questions after the current section
      let sectionEndIndex =
        sectionIndex < sectionMatches.length - 1
          ? sectionMatches[sectionIndex + 1].index
          : html.length;

      let sectionContentAll = html.slice(
        sectionMatch.index ?? 0,
        sectionEndIndex
      );

      let rollbackText = findRollBackTag(
        html,
        html.lastIndexOf(sectionMatch[0])
      );

      sectionContentAll = rollbackText + sectionContentAll;

      // Extract questions within the current section
      await extractQuestionsFromContent(
        sectionContentAll,
        questions,
        currentSection
      );
    }
  }

  return questions;
};

const findRollBackTag = (questionText: string, rollbackPos: number) => {
  let rollbackText = '';
  const originalPos = rollbackPos; // Store the original position for slicing content

  // Roll back through the text to find a closing tag
  while (rollbackPos > 0) {
    // Look for closing tag '</'
    if (
      questionText[rollbackPos] === '<' &&
      questionText[rollbackPos + 1] === '/'
    ) {
      // Move forward to find the closing '>' of the tag
      let rollbackPos2 = rollbackPos;
      while (rollbackPos2 < questionText.length) {
        if (questionText[rollbackPos2] === '>') {
          // Slice the content from after the closing '>' to the original position
          rollbackText = questionText.slice(rollbackPos2 + 1, originalPos);
          break;
        }
        rollbackPos2++;
      }
      break; // Exit the outer loop once the closing tag is found
    }
    rollbackPos--;
  }

  return rollbackText;
};

const findRollBackImgTag = (questionText: string, rollbackPos: number) => {
  let rollbackText = '';
  const originalPos = rollbackPos; // Store the original position for slicing content

  // Roll back through the text to find a closing tag
  while (rollbackPos > 0) {
    // Look for tag '<img', then return the content from the tag to the original position
    if (
      questionText[rollbackPos] === '<' &&
      questionText[rollbackPos + 1] === 'i' &&
      questionText[rollbackPos + 2] === 'm' &&
      questionText[rollbackPos + 3] === 'g'
    ) {
      rollbackText = questionText.slice(rollbackPos, originalPos);
      break;
    }
    rollbackPos--;
  }

  return rollbackText;
};

function getImageUrl(textContent: string): string {
  const match = textContent.match(/<img[^>]+src="([^">]+)"/);

  return match ? match[1] : '';
}

// Helper function to extract image dimensions
function getImageDimensions(
  textContent: string
): { width: number; height: number } | null {
  const widthMatch = textContent.match(/<img[^>]+width="([^">]+)"/);
  const heightMatch = textContent.match(/<img[^>]+height="([^">]+)"/);

  if (widthMatch && heightMatch) {
    const width = parseInt(widthMatch[1], 10);
    const height = parseInt(heightMatch[1], 10);
    return { width, height };
  }

  return null;
}

function modifyImageUrl(
  imageUrl: string,
  dimensions: { width: number; height: number }
): Promise<string> {
  return new Promise((resolve) => {
    const mimeType = imageUrl.match(/data:([^;]+)/)?.[1] || 'image/jpeg';
    if (!imageUrl.startsWith('data:image')) return resolve(imageUrl); // Only modify base64 images

    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = dimensions.width;
      canvas.height = dimensions.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, dimensions.width, dimensions.height);

      const resizedUrl = canvas.toDataURL(mimeType);

      resolve(resizedUrl); // Resolve the Promise with the resized URL
    };
  });
}

// Helper function to convert a base64 image URL to a File object
function convertBase64ToFile(base64Url: string, fileName: string): File | null {
  if (!base64Url.startsWith('data:image')) return null;
  const mimeType = base64Url.match(/data:([^;]+)/)?.[1] || 'image/jpeg';
  return base64ToFile(base64Url, fileName, mimeType);
}

function processImageTag(
  content: string,
  index: number,
  textContent: string,
  removeAtTheEnd: boolean = true
): string {
  if (removeAtTheEnd) textContent = removeImageAtTheEnd(textContent); // Check for any image at the end of the question text
  let rollbackImgText = findRollBackImgTag(content, index);
  if (stripHTMLTags(rollbackImgText).length === 0) {
    textContent = rollbackImgText + textContent;
  }

  return textContent;
}

async function getImageData(textContent: string, fileName: string) {
  let imageUrl = getImageUrl(textContent);

  const dimensions = getImageDimensions(textContent);

  // Modify imageUrl if dimensions exist
  if (imageUrl && dimensions) {
    imageUrl = await modifyImageUrl(imageUrl, dimensions);
  }

  let imageFile = convertBase64ToFile(imageUrl, fileName);

  return { imageUrl, imageFile };
}

/**
 * Extracts questions from the given section or entire content.
 */
const extractQuestionsFromContent = async (
  content: string,
  questions: Question[],
  currentSection: string
) => {
  // Extract the section content (the part before the first question or first reuse content)
  let sectionContentMatch = content.split(questionPattern)[0];
  let sectionContentMatch2 = content.split(reuseContentPattern)[0];
  if (sectionContentMatch2.length < sectionContentMatch.length) {
    sectionContentMatch = sectionContentMatch2;
  }
  let sectionTrim = sectionContentMatch.replace(currentSection, '').trim();

  sectionTrim = removeImageAtTheEnd(sectionTrim);

  // Exclude the section title from the section content
  let sectionContent = cleanHTMLTags(sectionTrim);

  let listReuseContent: Question[] = [];

  // Helper function to extract and add reuse content to listReuseContent
  const addReuseContent = async (
    match: RegExpExecArray,
    startIdx: number,
    endIdx: number
  ) => {
    const startQuestion = parseInt(match[startIdx], 10);
    const endQuestion = parseInt(match[endIdx], 10);
    let reuseContent = match[0];
    console.log('reuseContent:', reuseContent);
    let rollbackText = findRollBackTag(content, match.index);
    reuseContent = rollbackText + reuseContent;
    let { imageUrl: reuseContentImageUrl, imageFile: reuseContentImage } =
      await getImageData(
        processImageTag(content, match.index, reuseContent),
        'reuseContentImage.jpeg'
      );

    for (let i = startQuestion; i <= endQuestion; i++) {
      listReuseContent.push({
        questionId: `q_${i}_${currentSection}`,
        section: currentSection,
        sectionContent: sectionContent,
        index: i,
        type: 'mcq',
        correctAnswer: 'A',
        question: removeImageAtTheEnd(reuseContent),
        questionImageUrl: reuseContentImageUrl,
        explainImageUrl: '',
        questionImage: null,
        explain: '',
        explainImage: null,
        questionA: 'A',
        questionB: 'B',
        questionC: 'C',
        questionD: 'D',
        reuseContent: cleanHTMLTags(removeImageAtTheEnd(reuseContent)),
        reuseContentImage: reuseContentImage,
        reuseContentImageUrl: reuseContentImageUrl || '',
        questionAImage: null,
        questionAImageUrl: '',
        questionBImage: null,
        questionBImageUrl: '',
        questionCImage: null,
        questionCImageUrl: '',
        questionDImage: null,
        questionDImageUrl: '',
      });
    }
  };

  let reuseContentMatch;
  let listCutContent: string[] = [];
  while ((reuseContentMatch = reuseContentPattern.exec(content)) !== null) {
    if (reuseContentMatch) addReuseContent(reuseContentMatch, 1, 2);
    let cutContent = processImageTag(
      content,
      reuseContentMatch.index,
      reuseContentMatch[0]
    );
    listCutContent.push(cutContent);

    // content = content.replace(cutContent, '').trim();

    // if (cutContent.includes('<img')) {
    //   reuseContentPattern.exec(content);
    // }
  }

  for (let i = 0; i < listCutContent.length; i++) {
    content = content.replace(listCutContent[i], '').trim();
  }

  let reuseContentMatch2;
  listCutContent = [];
  while ((reuseContentMatch2 = reuseContentPattern2.exec(content)) !== null) {
    if (reuseContentMatch2) addReuseContent(reuseContentMatch2, 1, 2);
    let cutContent = processImageTag(
      content,
      reuseContentMatch2.index,
      reuseContentMatch2[0]
    );
    listCutContent.push(cutContent);
  }

  for (let i = 0; i < listCutContent.length; i++) {
    content = content.replace(listCutContent[i], '').trim();
  }

  let match;
  while ((match = questionPattern.exec(content)) !== null) {
    const index = parseInt(match[1], 10); // Extract question number (e.g., "Câu 1:")
    let questionText = match[2].trim(); // Extract the question text

    let options: { [key: string]: string } = { A: '', B: '', C: '', D: '' };

    let explain = ''; // Store the explanation for the answer
    let explainWithImage = ''; // Store the explanation with image for the answer

    // Find the explanation first (if any), and remove it from the question text
    let explainMatch = questionText.match(explainPattern);
    if (!explainMatch) {
      const explainMatch2 = questionText.match(explainPattern2);
      if (explainMatch2) {
        explainMatch = explainMatch2;
      }
    }

    if (explainMatch) {
      explain = explainMatch[0].trim();
      // Add "<span style="font-weight: bold; ">" to the beginning of the explain text
      //explain = '<span style="font-weight: bold;">' + explain;

      //Rollback to the previous position until find any closing tag, then add from after that position to the end of the string to the beginning of the explain text
      let rollbackText = findRollBackTag(
        questionText,
        questionText.lastIndexOf(explainMatch[0])
      );

      explain = rollbackText + explain;
      questionText = questionText.replace(explain, '').trim();

      explainWithImage = explain;

      explain = cleanHTMLTags(explain, true);
    } else {
      // Throw an error if the explanation is not found
      throw new Error(
        `Hướng dẫn không được tìm thấy cho câu hỏi ${index} trong phần ${currentSection}`
      );
    }

    // Find the correct answer in the explanation text and get the type of question based on the answer
    const stripExplain = stripHTMLTags(explain);
    const correctAnswerMatchMCQ = stripExplain.match(answerMCQPattern);
    const correctAnswerMatchMCTF = stripExplain.match(answerMCTFPattern);
    const correctAnswerMatchSA = stripExplain.match(answerSAPattern);

    let correctAnswer = '';
    let questionType: 'mcq' | 'mctf' | 'sa' = 'mcq'; // Assume multiple choice for now

    if (correctAnswerMatchMCQ) {
      correctAnswer = correctAnswerMatchMCQ[1].trim();
      questionType = 'mcq';
    } else if (correctAnswerMatchMCTF) {
      // find all 4 match and add '1' or '0' to the correct answer
      let copyExplain = stripExplain;
      while (true) {
        const nextMatch = copyExplain.match(answerMCTFPattern);
        if (nextMatch) {
          correctAnswer += nextMatch[2] === 'Đúng' ? '1' : '0';
          copyExplain = copyExplain.replace(nextMatch[0], '').trim();
        } else {
          break;
        }

        if (correctAnswer.length === 4) {
          break;
        }
      }

      questionType = 'mctf';
    } else if (correctAnswerMatchSA) {
      // Remove all the tags in the answer, only keep the text
      correctAnswer = stripHTMLTags(correctAnswerMatchSA[1]);
      correctAnswer = correctAnswer.trim();
      //Cut to max length of 4 characters
      correctAnswer = correctAnswer.slice(0, 4);
      questionType = 'sa';
    } else {
      // Throw an error if the correct answer is not found
      throw new Error(
        `Câu trả lời không được tìm thấy cho câu hỏi ${index} trong phần ${currentSection}`
      );
    }

    // Find the options A, B, C, D for each question based on the question type
    let optionMatch;
    let questionAImage: File | null = null;
    let questionBImage: File | null = null;
    let questionCImage: File | null = null;
    let questionDImage: File | null = null;
    let questionAImageUrl,
      questionBImageUrl,
      questionCImageUrl,
      questionDImageUrl;

    listCutContent = [];
    if (questionType === 'mcq') {
      while ((optionMatch = optionMCQPattern.exec(questionText)) !== null) {
        const optionLabel = optionMatch[1]; // e.g., "A", "B", "C", "D"
        let optionText = optionMatch[2].trim(); // Extract the text of the option

        options[optionLabel] = cleanHTMLTags(optionText);

        // Extract image data from the option text
        if (optionText.includes('<img')) {
          listCutContent.push(optionText);
          let { imageUrl, imageFile } = await getImageData(
            optionText,
            `question${optionLabel}Image.jpeg`
          );

          if (optionLabel === 'A') {
            questionAImage = imageFile;
            questionAImageUrl = imageUrl;
          } else if (optionLabel === 'B') {
            questionBImage = imageFile;
            questionBImageUrl = imageUrl;
          } else if (optionLabel === 'C') {
            questionCImage = imageFile;
            questionCImageUrl = imageUrl;
          } else if (optionLabel === 'D') {
            questionDImage = imageFile;
            questionDImageUrl = imageUrl;
          }
        }
      }
    } else if (questionType === 'mctf') {
      while ((optionMatch = optionMCTFPattern.exec(questionText)) !== null) {
        const optionLabel = optionMatch[1]; // e.g., "a", "b", "c", "d"
        let optionText = optionMatch[2].trim(); // Extract the text of the option

        options[optionLabel.toUpperCase()] = cleanHTMLTags(optionText);

        // Extract image data from the option text
        if (optionText.includes('<img')) {
          listCutContent.push(optionText);
          let { imageUrl, imageFile } = await getImageData(
            optionText,
            `question${optionLabel}Image.jpeg`
          );

          if (optionLabel === 'A') {
            questionAImage = imageFile;
            questionAImageUrl = imageUrl;
          } else if (optionLabel === 'B') {
            questionBImage = imageFile;
            questionBImageUrl = imageUrl;
          } else if (optionLabel === 'C') {
            questionCImage = imageFile;
            questionCImageUrl = imageUrl;
          } else if (optionLabel === 'D') {
            questionDImage = imageFile;
            questionDImageUrl = imageUrl;
          }
        }
      }
    } else {
      // For short answer questions, there are no options
      options = { A: '', B: '', C: '', D: '' };
    }

    // Extract the question text by splitting the question text before the first option based on the question type
    let extractQuestionText = '';

    if (questionType === 'mcq') {
      // Match everything up to the last "A." using a regular expression
      const match = questionText.match(
        /([\s\S]*?)(?=<span style="font-weight: bold; ">A\.)/
      );

      if (match) {
        extractQuestionText = cleanHTMLTags(match[0], true);
        questionText = match[0];
      } else {
        extractQuestionText = cleanHTMLTags(questionText, true); // fallback if no match found
      }
    } else if (questionType === 'mctf') {
      // Match everything up to the last "a)" using a regular expression
      const match = questionText.match(
        /([\s\S]*?)(?=<span style="font-weight: bold; ">a\))/i
      );

      if (match) {
        extractQuestionText = cleanHTMLTags(match[0], true);
        questionText = match[0];
      } else {
        extractQuestionText = cleanHTMLTags(questionText, true); // fallback if no match found
      }
    } else {
      extractQuestionText = cleanHTMLTags(questionText, true);
    }

    let { imageUrl: questionImageUrl, imageFile: questionImage } =
      await getImageData(
        processImageTag(content, match.index, questionText, false),
        'questionImage.jpeg'
      );

    if (questionDImage !== null) {
      explainWithImage = removeImageAtTheEnd(explainWithImage);
    } else {
      explainWithImage = processImageTag(
        content,
        content.indexOf(explainWithImage),
        explainWithImage
      );
    }
    let { imageUrl: explainImageUrl, imageFile: explainImage } =
      await getImageData(explainWithImage, 'explainImage.jpeg');

    // Create the question object
    const question: Question = {
      questionId: `q_${index}_${currentSection}`, // Generate a unique ID
      section: currentSection || '', // Section of the question
      sectionContent: sectionContent, // Section content (if any)
      index,
      type: questionType, // Assume multiple choice for now
      correctAnswer, // The extracted correct answer
      question: extractQuestionText, // Extract the part before options
      questionImage: questionImage,
      questionImageUrl: questionImageUrl || '', // Attach image URL (if any)
      explain: explain, // Store answer here
      explainImage: explainImage,
      explainImageUrl: explainImageUrl || '', // No answer URL for now
      questionA: options.A,
      questionB: options.B,
      questionC: options.C,
      questionD: options.D,
      reuseContent: '', // No reuse content for now
      reuseContentImage: null,
      reuseContentImageUrl: '', // No reuse content URL for now
      questionAImage: questionAImage,
      questionAImageUrl: questionAImageUrl || '',
      questionBImage: questionBImage,
      questionBImageUrl: questionBImageUrl || '',
      questionCImage: questionCImage,
      questionCImageUrl: questionCImageUrl || '',
      questionDImage: questionDImage,
      questionDImageUrl: questionDImageUrl || '',
    };

    // Add the question to the list
    questions.push(question);
  }

  // Assign reuse content questions to the main questions list by matching the index and section
  listReuseContent.forEach((reuseQuestion) => {
    const mainQuestion = questions.find(
      (question) =>
        question.index === reuseQuestion.index &&
        question.section === reuseQuestion.section
    );

    if (mainQuestion) {
      mainQuestion.reuseContent = reuseQuestion.reuseContent;
      mainQuestion.reuseContentImage = reuseQuestion.reuseContentImage;
      mainQuestion.reuseContentImageUrl = reuseQuestion.reuseContentImageUrl;
    }
  });
};

function base64ToFile(
  base64String: string,
  fileName: string,
  mimeType: string
): File {
  const byteString = atob(base64String.split(',')[1]); // Decode base64 string (remove data URL part)
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab], fileName, { type: mimeType });
}

/**
 * Clean and fix HTML tags to ensure proper formatting, and add <p> tags if necessary.
 */
const cleanHTMLTags = (content: string, withptag: boolean = false): string => {
  const allowedTags = [
    'math',
    'mn',
    'mo',
    'mi',
    'mrow',
    'msup',
    'msub',
    'msubsup',
    'mtable',
    'mtr',
    'mtd',
    'mfenced',
    'maligngroup',
    'sup',
    'sub',
    'mfrac',
    'msqrt',
    'mtext',
    'munder',
    'mover',
    'mspace',
    'span',
    'table',
    'tr',
    'td',
  ];

  // Add <p> tag if withptag is true
  if (withptag) {
    allowedTags.push('p');
  }

  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: allowedTags, // Allow math-related tags and <p> if specified
    ALLOWED_ATTR: [
      'style',
      'display',
      'xmlns',
      'class',
      'mathvariant',
      'data-*', // Allow custom data attributes if any
      'open',
      'separators',
      'close',
    ], // Allow essential attributes for math and style
    KEEP_CONTENT: true, // Ensure that no child content is stripped out
  });
};

// Remove all HTML tags, just keep the text content and the img tag
// const stripHTMLTagsAndImg = (content: string): string => {
//   return sanitizeHtml(content, {
//     allowedTags: ['img'], // Allow img tag
//     allowedAttributes: {
//       img: ['src', 'alt'], // Allow specific attributes for img
//     },
//   });
// };

// Remove all HTML tags from the content
const stripHTMLTags = (content: string): string => {
  return sanitizeHtml(content, {
    allowedTags: [],
    allowedAttributes: {},
  });
};

function processMathContent(htmlContent: string, splitTag: string): string {
  // Match all math spans in the HTML content
  const mathContent = htmlContent.match(
    /<span class="math">([\s\S]*?)<\/span>/g
  );

  // check if mathContent have <maligngroup> tag, if yes, exit the function
  // if (htmlContent.includes('<maligngroup>')) {
  //   return htmlContent;
  // }

  // Set a threshold for when the math content is considered "too long"
  const maxLength = 24;

  if (mathContent) {
    mathContent.forEach((content) => {
      // Remove HTML tags to calculate the pure math content length
      const plainTextContent = content
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, '');

      // Check if the content length exceeds the threshold
      if (plainTextContent.length <= maxLength) {
        // If the content is not too long, skip splitting and move to the next item
        return;
      }

      let newContent = '';

      // Split the content using the provided splitTag
      const splitContent = content.split(splitTag);

      // Loop through each part except the last, and append the splitTag along with custom tags
      let isInsideMtable = false;
      for (let i = 0; i < splitContent.length - 1; i++) {
        const leftSide = splitContent[i];

        //Check if the position of the splitContent[i] is inside a <mtable> tag, if yes, skip the split
        if (leftSide.includes('<mtable')) {
          isInsideMtable = true;
        }

        if (leftSide.includes('</mtable>') && !leftSide.includes('<mtable')) {
          isInsideMtable = false;
        }

        if (isInsideMtable) {
          newContent += leftSide + splitTag;
          continue;
        }

        // Properly restructure the new content with span and math tags
        newContent += `${leftSide}<mspace width="5px"/></math></span><span class="math"><math>${splitTag}`;
      }

      // Add the last part (after the final split tag)
      const lastContent = splitContent[splitContent.length - 1];
      newContent += lastContent;

      // Replace the original content in htmlContent with the updated structure
      htmlContent = htmlContent.replace(content, newContent);
    });
  }

  return htmlContent;
}

// Function to replace <mo>,</mo> with <mn>,</mn> in the math content
const processMathCommaDot = (htmlContent: string): string => {
  // Match all math spans in the HTML content
  const mathContent = htmlContent.match(
    /<span class="math">([\s\S]*?)<\/span>/g
  );

  if (mathContent) {
    mathContent.forEach((content) => {
      // Replace <mo>,</mo> with <mn>,</mn> in the math content
      const newContent = content.replace('<mo>,</mo>', '<mn>,</mn>');
      const newContent2 = newContent.replace('<mo>.</mo>', '<mn>.</mn>');

      // Replace the original content in htmlContent with the updated structure
      htmlContent = htmlContent.replace(content, newContent2);
    });
  }

  return htmlContent;
};

// Function to render HTML with sanitization
export const RenderHTMLContent = (htmlContent: string) => {
  htmlContent = processMathCommaDot(htmlContent);

  return (
    <MathJax
      inline={true}
      onError={(e) => {
        console.log('MathJax error:', e);
      }}
    >
      <span
        className="mathjax"
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      />
    </MathJax>
  );
};

// type RenderHTMLContent2Props = {
//   htmlContent: string;
//   label?: string; // Optional label prop
// };

// export const RenderHTMLContent2 = ({
//   htmlContent,
//   label,
// }: RenderHTMLContent2Props) => {
//   // Preprocess the HTML content
//   htmlContent = processMathContent(htmlContent, '<mo>=</mo>');
//   htmlContent = processMathContent(htmlContent, '<mo>≈</mo>');
//   htmlContent = processMathContent(htmlContent, '<mo>→</mo>');
//   htmlContent = processMathCommaDot(htmlContent);

//   // If label is provided, prepend it as <strong>{label}</strong>
//   if (label) {
//     htmlContent = `<strong>${label}</strong> ${htmlContent}`;
//   }

//   return (
//     <Box
//       sx={{
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         width: '100%',
//       }}
//     >
//       <MathJax
//         inline={true}
//         onError={(e) => {
//           console.log('MathJax error:', e);
//         }}
//       >
//         <span
//           className="mathjax"
//           dangerouslySetInnerHTML={{
//             __html: htmlContent,
//           }}
//         />
//       </MathJax>
//     </Box>
//   );
// };

// Function to render HTML with sanitization
export const RenderHTMLContent2 = (htmlContent: string, label?: string) => {
  htmlContent = processMathContent(htmlContent, '<mo>=</mo>');
  htmlContent = processMathContent(htmlContent, '<mo>≈</mo>');
  htmlContent = processMathContent(htmlContent, '<mo>→</mo>');

  htmlContent = processMathCommaDot(htmlContent);

  if (label) {
    htmlContent = `<strong>${label}</strong> ${htmlContent}`;
  }

  // console.log('htmlContent:', htmlContent);

  return (
    <Box
      sx={{
        // overflowX: 'auto',
        // overflowY: 'hidden',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <MathJax
        inline={true}
        onError={(e) => {
          console.log('MathJax error:', e);
        }}
      >
        <span
          className="mathjax"
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      </MathJax>
    </Box>
  );
};

// export const RenderHTMLContent = (htmlContent: string) => {
//   useEffect(() => {
//     if (window.MathJax) {
//       window.MathJax.typeset(); // Re-process MathJax
//     }
//   }, [htmlContent]);

//   return (
//     <span
//       className="mathjax"
//       dangerouslySetInnerHTML={{
//         __html: htmlContent,
//       }}
//     />
//   );
// };

// export const RenderHTMLContent2 = (htmlContent: string) => {
//   useEffect(() => {
//     if (window.MathJax) {
//       window.MathJax.typeset(); // Re-process MathJax
//     }
//   }, [htmlContent]);

//   return (
//     <Box
//       sx={{
//         overflowX: 'auto',
//         overflowY: 'hidden',
//         width: '100%',
//       }}
//     >
//       <span
//         className="mathjax"
//         dangerouslySetInnerHTML={{
//           __html: htmlContent,
//         }}
//       />
//     </Box>
//   );
// };

const removeImageAtTheEnd = (sectionTrim: string): string => {
  //Find the image tag at the end of the section
  const sectionImageMatch = sectionTrim.match(/<img[^>]+>/g);

  if (sectionImageMatch) {
    const sectionTrimEnd = sectionTrim.slice(
      sectionTrim.lastIndexOf(sectionImageMatch[0])
    );

    if (stripHTMLTags(sectionTrimEnd).length === 0) {
      // If the image is at the end of the section, remove from its position to the end of the section
      sectionTrim = sectionTrim.slice(
        0,
        sectionTrim.lastIndexOf(sectionImageMatch[0])
      );
      return sectionTrim;
    }
  }
  return sectionTrim;
};

// Helper function to check if the exam is a math exam in THPT Quốc gia, if there are 12 mcq questions, 4 mctf questions, and 6 sa questions then it is a math exam
const isMathTHPT = (questions: any[]) => {
  const mcq = questions.filter((q) => q.type === 'mcq');
  const mctf = questions.filter((q) => q.type === 'mctf');
  const sa = questions.filter((q) => q.type === 'sa');
  return mcq.length === 12 && mctf.length === 4 && sa.length === 6;
};

// Helper function to check if the exam is a physic or chemical exam in THPT Quốc gia, if there are 18 mcq questions, 4 mctf questions, and 6 sa questions then it is a math exam
const isPhysicChemicalTHPT = (questions: any[]) => {
  const mcq = questions.filter((q) => q.type === 'mcq');
  const mctf = questions.filter((q) => q.type === 'mctf');
  const sa = questions.filter((q) => q.type === 'sa');
  return mcq.length === 18 && mctf.length === 4 && sa.length === 6;
};

//mctf questions correct answers are stored as an array of strings like '1001', it's like true false false true is the correct answer

// Helper function to calculate the score of a student
export const CalculateScore = (answers: any[], questions: any[]) => {
  let score = 0;
  if (isMathTHPT(questions)) {
    // If is a math exam, mcq questions are worth 0.25 points, mctf questions are worth 0.1 (if 1 correct answer) or 0.25 (if 2 correct answers),
    // 0.5 (if 3 correct answers), and 1 (if 4 correct answers), sa questions are worth 0.5 points, max score is 10
    answers.forEach((answer) => {
      const question = questions.find(
        (q) => q.questionId === answer.questionId
      );
      if (
        question?.type === 'mcq' &&
        question.correctAnswer === answer.answer
      ) {
        score += 0.25;
      } else if (question?.type === 'mctf') {
        const correctAnswers = question.correctAnswer.split('');
        const studentAnswers = answer.answer.split('');
        let correctCount = 0;
        for (let i = 0; i < correctAnswers.length; i++) {
          if (correctAnswers[i] === studentAnswers[i]) {
            correctCount++;
          }
        }
        if (correctCount === 1) {
          score += 0.1;
        } else if (correctCount === 2) {
          score += 0.25;
        } else if (correctCount === 3) {
          score += 0.5;
        } else if (correctCount === 4) {
          score += 1;
        }
      } else if (
        question?.type === 'sa' &&
        question.correctAnswer === answer.answer
      ) {
        score += 0.5;
      }
    });
  } else if (isPhysicChemicalTHPT(questions)) {
    // If is a physic or chemical exam, mcq questions are worth 0.25 points,  mctf questions are worth 0.1 (if 1 correct answer) or 0.25 (if 2 correct answers),
    // 0.5 (if 3 correct answers), and 1 (if 4 correct answers), sa questions are worth 0.25 points, max score is 10
    answers.forEach((answer) => {
      const question = questions.find(
        (q) => q.questionId === answer.questionId
      );
      if (
        question?.type === 'mcq' &&
        question.correctAnswer === answer.answer
      ) {
        score += 0.25;
      } else if (question?.type === 'mctf') {
        const correctAnswers = question.correctAnswer.split('');
        const studentAnswers = answer.answer.split('');
        let correctCount = 0;
        for (let i = 0; i < correctAnswers.length; i++) {
          if (correctAnswers[i] === studentAnswers[i]) {
            correctCount++;
          }
        }
        if (correctCount === 1) {
          score += 0.1;
        } else if (correctCount === 2) {
          score += 0.25;
        } else if (correctCount === 3) {
          score += 0.5;
        } else if (correctCount === 4) {
          score += 1;
        }
      } else if (
        question?.type === 'sa' &&
        question.correctAnswer === answer.answer
      ) {
        score += 0.25;
      }
    });
  } else {
    // Each question is worth 10/number of questions points
    const pointPerQuestion = 10 / questions.length;
    answers.forEach((answer) => {
      const question = questions.find(
        (q) => q.questionId === answer.questionId
      );
      if (
        question?.type === 'mcq' &&
        question.correctAnswer === answer.answer
      ) {
        score += pointPerQuestion;
      } else if (
        question?.type === 'mctf' &&
        question.correctAnswer === answer.answer
      ) {
        score += pointPerQuestion;
      } else if (
        question?.type === 'sa' &&
        question.correctAnswer === answer.answer
      ) {
        score += pointPerQuestion;
      }
    });

    // Round the score to 2 decimal places
    score = Math.round(score * 100) / 100;
  }

  return score;
};

//Helper function to get the 3 numbers, first is the number of correct answers, second is the number of wrong answers, and the third is the number of unanswered questions
export const getAnswerStats = (answers: any[], questions: any[]) => {
  let correct = 0;
  let wrong = 0;
  let unanswered = 0;
  answers.forEach((answer) => {
    const question = questions.find((q) => q.questionId === answer.questionId);
    if (question?.type === 'mcq' && question.correctAnswer === answer.answer) {
      correct++;
    } else if (
      question?.type === 'mctf' &&
      question.correctAnswer === answer.answer
    ) {
      correct++;
    } else if (
      question?.type === 'sa' &&
      question.correctAnswer === answer.answer
    ) {
      correct++;
    } else if (answer.answer === '') {
      unanswered++;
    } else {
      wrong++;
    }
  });
  return [correct, wrong, unanswered];
};

export const sortResults = (
  results: Result[],
  exam: Exam | null | undefined
) => {
  //Calculate the score of each student by counting the correct answers
  //Sort the results by score and time
  if (!exam) return results;
  const sortedResults = results
    .map((result) => {
      const score = CalculateScore(result.answers, exam.questions);
      return { ...result, score };
    })
    .sort((a, b) => {
      if (a.score === b.score) {
        return a.completeTime - b.completeTime;
      }
      return b.score - a.score;
    });
  return sortedResults;
};

// Return a string like '3/100' or '10/10' based on the rank and the total number of results
export const getRank = (result: Result) => {
  if (!result.exam || !result.otherResults) return '';
  const sortedResults = sortResults(result.otherResults, result.exam);
  const rank = sortedResults.findIndex((r) => r.studentId === result.studentId);
  return `${rank + 1}/${sortedResults.length}`;
};

// Helper function to format time in minutes and seconds: '1m30s' or '30s'
export const formatCompleteTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return `${remainingSeconds}s`;
  }

  return `${minutes}:${remainingSeconds}`;
};
